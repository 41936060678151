import {
  defineBreadcrumb,
  useI18n,
  useSchemaOrg
} from "#imports";
import { useSiteConfig } from "#site-config/app/composables/useSiteConfig";
import { createSitePathResolver } from "#site-config/app/composables/utils";
import { defu } from "defu";
import { fixSlashes } from "nuxt-site-config/urls";
import { useNuxtApp, useRoute, useRouter, useState } from "nuxt/app";
import { withoutTrailingSlash } from "ufo";
import { computed, getCurrentInstance, inject, onScopeDispose, onUnmounted, provide, ref, toRaw, toValue } from "vue";
import { pathBreadcrumbSegments } from "../../shared/breadcrumbs.js";
function withoutQuery(path) {
  return path.split("?")[0];
}
function titleCase(s) {
  return s.replaceAll("-", " ").replace(/\w\S*/g, (w) => w.charAt(0).toUpperCase() + w.substr(1).toLowerCase());
}
const BreadcrumbCtx = Symbol("BreadcrumbCtx");
export function useBreadcrumbItems(_options = {}) {
  const vm = getCurrentInstance();
  const id = _options.id || "breadcrumb";
  const pauseUpdates = ref(import.meta.client);
  let stateRef = null;
  if (vm) {
    stateRef = inject(BreadcrumbCtx, null);
    if (!stateRef) {
      stateRef = ref({});
      provide(BreadcrumbCtx, stateRef);
    }
    const state = stateRef.value;
    if (!state[id]) {
      state[id] = [];
    }
    const idx = state[id].push(_options) - 1;
    stateRef.value = state;
    onUnmounted(() => {
      const state2 = toRaw(stateRef.value);
      if (state2[id]) {
        state2[id] = state2[id].map((_, i) => i === idx ? false : _);
        stateRef.value = state2;
      }
    });
    if (import.meta.client) {
      const nuxtApp = useNuxtApp();
      const _ = [];
      _.push(nuxtApp.hooks.hook("page:start", () => {
        pauseUpdates.value = true;
      }));
      _.push(nuxtApp.hooks.hook("page:finish", () => {
        if (!nuxtApp.isHydrating) {
          pauseUpdates.value = false;
        }
      }));
      _.push(nuxtApp.hooks.hook("app:error", () => {
        pauseUpdates.value = false;
      }));
      _.push(nuxtApp.hooks.hook("app:suspense:resolve", () => {
        pauseUpdates.value = false;
      }));
      onScopeDispose(() => {
        _.forEach((hook) => hook?.());
        _.length = 0;
      });
    }
  }
  const route = useRoute();
  const router = useRouter();
  const i18n = useI18n();
  const siteResolver = createSitePathResolver({
    canonical: true,
    absolute: true
  });
  const lastBreadcrumbs = useState(`nuxt-seo:breadcrumb:${id}`, () => []);
  const siteConfig = useSiteConfig();
  const items = computed(() => {
    if (import.meta.client && pauseUpdates.value) {
      return lastBreadcrumbs.value;
    }
    const optionStack = stateRef?.value?.[id] || [_options];
    const flatOptions = toRaw([...optionStack]).filter(Boolean).reduce((acc, cur) => {
      acc.rootSegment = acc.rootSegment || cur.rootSegment;
      acc.path = acc.path || cur.path;
      return acc;
    }, {});
    let rootNode = flatOptions.rootSegment || "/";
    if (i18n) {
      if (i18n.strategy === "prefix" || i18n.strategy !== "no_prefix" && toValue(i18n.defaultLocale) !== toValue(i18n.locale))
        rootNode = `${rootNode}${toValue(i18n.locale)}`;
    }
    const current = withoutQuery(withoutTrailingSlash(toValue(flatOptions.path) || toRaw(route).path || rootNode));
    const allOverrides = toRaw([...optionStack]).filter(Boolean)?.map((opts) => toValue(opts.overrides)).filter(Boolean);
    const flatOverrides = allOverrides?.reduce((acc, i) => {
      if (i) {
        i.forEach((item, index) => {
          if (item !== void 0) {
            acc[index] = item;
          }
        });
      }
      return acc;
    }, []) || {};
    const segments = pathBreadcrumbSegments(current, rootNode).map((path, index) => {
      let item = {
        to: path
      };
      if (typeof flatOverrides[index] !== "undefined") {
        if (flatOverrides[index] === false)
          return false;
        item = defu(flatOverrides[index], item);
      }
      return item;
    });
    const allPrepends = toRaw([...optionStack]).filter(Boolean).flatMap((opts) => toValue(opts.prepend)).filter(Boolean);
    const allAppends = toRaw([...optionStack]).filter(Boolean).flatMap((opts) => toValue(opts.append)).filter(Boolean);
    if (allPrepends.length)
      segments.unshift(...allPrepends);
    if (allAppends.length)
      segments.push(...allAppends);
    lastBreadcrumbs.value = segments.filter(Boolean).map((item) => {
      let fallbackLabel = titleCase(String((item.to || "").split("/").pop()));
      let fallbackAriaLabel = "";
      const route2 = item.to ? router.resolve(item.to)?.matched?.[0] : null;
      if (route2) {
        const routeMeta = route2?.meta || {};
        if (routeMeta.breadcrumb) {
          item = {
            ...item,
            ...routeMeta.breadcrumb
          };
        }
        const routeName = String(route2.name).split("___")[0];
        if (routeName === "index") {
          fallbackLabel = "Home";
        }
        fallbackLabel = routeMeta.breadcrumbLabel || routeMeta.breadcrumbTitle || routeMeta.title || fallbackLabel;
        fallbackLabel = i18n.t(`breadcrumb.items.${routeName}.label`, fallbackLabel, { missingWarn: false });
        fallbackAriaLabel = i18n.t(`breadcrumb.items.${routeName}.ariaLabel`, fallbackAriaLabel, { missingWarn: false });
      }
      item.label = item.label || fallbackLabel;
      item.ariaLabel = item.ariaLabel || fallbackAriaLabel || item.label;
      item.current = item.current || item.to === current;
      if (toValue(flatOptions.hideCurrent) && item.current)
        return false;
      return item;
    }).map((m) => {
      if (m && m.to) {
        m.to = fixSlashes(siteConfig.trailingSlash, m.to);
        if (m.to === rootNode && toValue(flatOptions.hideRoot))
          return false;
      }
      return m;
    }).filter(Boolean);
    return lastBreadcrumbs.value;
  });
  const schemaOrgEnabled = typeof _options.schemaOrg === "undefined" ? true : _options.schemaOrg;
  if ((import.meta.dev || import.meta.server || import.meta.env?.NODE_ENV === "test") && schemaOrgEnabled) {
    useSchemaOrg([
      // @ts-expect-error untyped
      defineBreadcrumb({
        id: `#${id}`,
        itemListElement: computed(() => items.value.map((item) => ({
          name: item.label || item.ariaLabel,
          item: item.to ? siteResolver(item.to) : void 0
        })))
      })
    ]);
  }
  return items;
}
